import React from "react";
import Marquee from "react-fast-marquee";
import useVideoStore from '../store/useVideoStore';


export default function SongTitleRunning() {
    const playList = useVideoStore((state) => state.playList);
    const playState = useVideoStore((state) => state.playState);

    return (
        <div style={{ fontSize: '30px', color: '#FFFF00', fontWeight: 700 }}>
            {/* {console.log('SongTitleRunning - playState: ' + playState)} */}
            {
                playList.length > 0 ?
                    playState === 'play' ?
                        playList.length === 1 ?
                            <Marquee gradient={false} speed={40} >
                                <div style={{ padding: '0px 30px' }}>
                                    đang phát: {`${ playList[0].title}`}
                                </div>
                            </Marquee>
                            :
                            <Marquee gradient={false} speed={40} >
                                <div style={{ padding: '0px 30px' }}>
                                    đang phát: {`${ playList[0].title}`}
                                </div>
                                <div style={{ padding: '0px 30px' }}>
                                    bài kế tiếp: {`${ playList[1].title}`}
                                </div>
                            </Marquee>
                        :
                        ''
                    :
                    ''
            }
        </div>
    )
}