import React, { useRef, useEffect } from 'react';
import { create, CreateTypes } from "canvas-confetti";

export default function RandomDirection(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    const fire = () => {
        if (fireRef.current) {
            fireRef.current({
                angle: randomInRange(55, 125),
                spread: randomInRange(50, 70),
                particleCount: randomInRange(50, 100),
                origin: { y: 0.6 }
            });

            var i = 0;

            var interval = setInterval(() => {
                if (i < 3) {
                    ++i
                    console.log('i: ' + i);
                } else {
                    console.log('i am here at clearInterval');
                    fireRef.current.reset();
                    socket.removeEmojiListSocket();
                    clearInterval(interval);
                }

            }, 1000);
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}