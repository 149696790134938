import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function BasicCannon(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    const fire = () => {
        if (fireRef.current) {
            fireRef.current({
                particleCount: 200,
                // colors: ["#B22222", "#E25822", "#F1BC31", "#F6F052"],
                shapes: ["circle"],
                startVelocity: 30,
                spread: 360
            });

            var i = 0;
            var interval = setInterval(() => {
                if (i < 3) {
                    ++i
                    console.log('i: ' + i);
                } else {
                    console.log('i am here at clearInterval');
                    fireRef.current.reset();
                    socket.removeEmojiListSocket();
                    clearInterval(interval);
                }

            }, 1000);
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}