import React from 'react';
import NormalSnow from './NormalSnow';
import ColorSnow from './ColorSnow';
import HeavySnow from './HeavySnow';
import FastSnow from './FastSnow';

export default function FallingSnowLanding(props) {
    const {
        socket,
        emotionData
    } = props;

    //console.log('FallingSnowLanding: ' + JSON.stringify(emotionData))

    function fallingSnowNavigator() {
        switch (emotionData[0].id) {

            case 'normal_falling_snow':
                return <div>
                    <NormalSnow socket={socket} />
                </div>

            case 'color_falling_snow':
                return <div>
                    <ColorSnow snowColor={emotionData[0].color} socket={socket} />
                </div>

            case 'heavy_falling_snow':
                return <div>
                    <HeavySnow socket={socket} />
                </div>

            case 'fast_falling_snow':
                return <div>
                    <FastSnow socket={socket} />
                </div>

            default:
                return null
        }
    }
    return (
        <div>
            {fallingSnowNavigator()}
        </div>
    );
}