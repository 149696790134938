import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function SchoolPride(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    var end = Date.now() + (15 * 1000);
    var colors = ['#bb0000', '#ffffff'];

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    const fire = () => {
        if (fireRef.current) {
            fireRef.current({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            fireRef.current({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(fire);
            } else {
                console.log('i am here at clearInterval');
                fireRef.current.reset();
                socket.removeEmojiListSocket();
            }
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}