import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function FireWorks(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    const fire = () => {
        if (fireRef.current) {
            var interval = setInterval(function () {
                var timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    console.log('i am here at clearInterval');
                    fireRef.current.reset();
                    socket.removeEmojiListSocket();
                    clearInterval(interval);
                }

                var particleCount = 50 * (timeLeft / duration);

                fireRef.current({ defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
                fireRef.current({ defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });

                //fireRef.current({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
                //fireRef.current({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
            }, 250);

            
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}