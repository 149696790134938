import { useEffect, useState } from "react";


export default function CountDownNow(props) {
    const {
        setDisplayTokenAction,
        onDisplayToken
    } = props;

    const [tokenTiming, setTokenTiming] = useState(20);

    useEffect(() => {
        let timer = null;

        if (onDisplayToken) {
            timer = tokenTiming > 0 && setInterval(() => setTokenTiming(tokenTiming - 1), 1000);
            tokenTiming === 0 && setDisplayTokenAction('');
            return () => clearInterval(timer);
        } else {
            clearInterval(timer);
            setDisplayTokenAction('');
        }
        
    }, [onDisplayToken, setDisplayTokenAction, tokenTiming, setTokenTiming]);
    

    return (
        <div >
            {tokenTiming > 0 ?
                <div style={{ fontSize: '10px'}}>hết hạn trong {tokenTiming} giây</div>
                :
                <div></div>
            }
        </div>
    )
}
