import Snowflakes from 'magic-snowflakes';

export default function NormalSnow(props) {
    const {
        socket,
    } = props;

    const snowflakes = new Snowflakes();
    snowflakes.start();

    var i = 0;
    var interval = setInterval(function () {
        ++i;
        if (i === 20) {
            snowflakes.destroy();
            clearInterval(interval);
            socket.removeEmojiListSocket();
        }
    }, 200);
}
