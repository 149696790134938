import React from 'react';

import SingleTopDown from './SingleTopDown';
import SingleBottomUp from './SingleBottomUp';
import SingleLeftRight from './SingleLeftRight';
import SingleRightLeft from './SingleRightLeft';

import MultipleTopDown from './MultipleTopDown';
import MultipleBottomUp from './MultipleBottomUp';
import MultipleLeftRight from './MultipleLeftRight';
import MultipleRightLeft from './MultipleRightLeft';

export default function EmojiLanding(props) {
    const {
        socket,
        emotionData
    } = props;

    //console.log('EmojiLanding - emotionData: ' + JSON.stringify(emotionData));

    function emojiNavigator() {
        switch (emotionData[0].id) {

            case 'single_topdown_emoji':
                return <SingleTopDown
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'single_bottomup_emoji':
                return <SingleBottomUp
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'single_leftright_emoji':
                return <SingleLeftRight
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'single_rightleft_emoji':
                return <SingleRightLeft
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'multiple_topdown_emoji':
                return <MultipleTopDown
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'multiple_bottomup_emoji':
                return <MultipleBottomUp
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'multiple_leftright_emoji':
                return <MultipleLeftRight
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            case 'multiple_rightleft_emoji':
                return <MultipleRightLeft
                    socket={socket}
                    emojiMessage={emotionData[0].emoji}
                />

            default:
                return null
        }
    }

    return (
        <div>
            {emojiNavigator()}
        </div>
    );
}