import React, { useRef, useState, useEffect } from 'react';
import { create, CreateTypes } from "canvas-confetti";

export default function BalloonReward(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);
    const [fireStart, setFireStart] = useState(false);

    useEffect(() => {
        if (canvasRef.current) {
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
        }
    }, []);

    const fire = () => {
        if (fireRef.current) {
            fireRef.current({
                particleCount: 200,
                // colors: ["#B22222", "#E25822", "#F1BC31", "#F6F052"],
                shapes: ["circle"],
                startVelocity: 30,
                spread: 360
            });
        }
    };

    useEffect(() => {
        console.log('emotionData: ' + JSON.stringify(emotionData));
        
        fire();

        const timerID = setInterval(() => {
            console.log('i am here at setTimeout')
            fireRef.current.reset();
            socket.removeEmojiListSocket();
        }, 3000);

        return function cleanup() {
            clearInterval(timerID);
        };

    }, [emotionData]);

    return (
        <div style={{


        }}>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
                <button onClick={fire}>Fire</button>
            </div>
        </div>
    );
}