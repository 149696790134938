import React from 'react';

import VideoController from '../VideoController';
import PlayerInfoDisplay from './PlayerInfoDisplay';
import FeatureLanding from '../features/FeatureLanding';

export default function PlayerLanding(props) {
    const {
        socket,
        videoMuted
    } = props;

    return (
        <div>
            <VideoController
                socket={socket}
                videoMuted={videoMuted}
            />
            <PlayerInfoDisplay />
            <FeatureLanding
                socket={socket}
            />
        </div>
    )
}