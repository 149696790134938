import React from "react";
import { isSmartTV, browserName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import useStore from '../useStore';


export default function PlayerConnect(props) {
    const {
        socket,
        setVideoMuted
    } = props;

    const connecting = useStore((state) => state.connecting);
    const setConnectingAction = useStore((state) => state.setConnectingAction);

    const connectSocketServer = () => {
        setConnectingAction(true);

        const clientName = "123";
        const clientRole = "player";
        const clientBrowser = browserName;
        socket.playerLogIn({ clientName, clientRole, clientBrowser });

        if (isSmartTV) {
            toggleFullSceen(document.body);

        } else {
            if (browserName === 'Firefox') {
                toggleFullSceen(document.body);
            }
        }

        setVideoMuted(false);
        //setLanding(true);
    };

    function toggleFullSceen(elem) {
        // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            if (elem.requestFullScreen) {
                elem.requestFullScreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullScreen) {
                elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    return (
        <div>
            {
                connecting ?
                    null
                    :
                    <div style={{
                        textAlign: 'center',
                        paddingTop: '15%'
                    }}>
                        <div style={{
                            color: '#e91e63',
                            fontSize: '20pt',
                            fontWeight: 700,
                            marginBottom: '20px'
                        }}>
                            Chào mừng bạn đến với Karaoke Hát Vui Vẻ
                        </div>
                        <button
                            type="button"
                            id="btn"
                            onClick={() => connectSocketServer()}
                            style={{
                                padding: '5px 30px',
                            }}
                        >
                            Kết Nối
                        </button>
                    </div>
            }
        </div>
    )
}