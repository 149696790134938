import React, { useState, useEffect } from 'react';
import { useReward } from 'react-rewards';

const config = {
    emoji: ['🍻', '🍷', '😋', '🍸', '🥳', '💃', '🏆', '🏅', '✌️'],
    elementCount: 100,
    spread: 150,
    zIndex: 30,
    lifetime: 300
};

export default function CheerReward(props) {
    const {
        socket,
        emotionData
    } = props;

    const [rewardStarted, setRewardStarted] = useState(false);
    const { reward: emojiReward, isAnimating: isEmojiAnimating } = useReward('emojiReward', 'emoji', config);

    useEffect(() => {
        emojiReward();
    }, [emotionData]);

    useEffect(() => {
        if (isEmojiAnimating) {
            setRewardStarted(true);
            console.log('START');
        } 
    }, [isEmojiAnimating]);

    useEffect(() => {
        if (rewardStarted === true && isEmojiAnimating === false) {
            console.log('END');
            socket.removeEmojiListSocket();
            setRewardStarted(false);
        } 
    }, [rewardStarted, isEmojiAnimating]);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 20
        }}>
            <div style={{
                marginTop: '20px',
                position: 'absolute',
                top: '60%',
                left: '50%',
                zIndex: 20
            }}>
                <span id="emojiReward" type="emoji" config={config} />
            </div>
        </div>
    );
}