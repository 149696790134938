import React from 'react';

import ConfettiReward from './ConfettiReward';
import BalloonReward from './BalloonReward';
import GiftReward from './GiftReward';
import StopReward from './StopReward';
import AngryReward from './AngryReward';
import CheerReward from './CheerReward';
import CustomReward from './CustomReward';

export default function RewardLanding(props) {
    const {
        socket,
        emotionData
    } = props;

    //console.log('RewardLanding: ' + JSON.stringify(emotionData))

    function rewardNavigator() {
        switch (emotionData[0].id) {

            case 'confetti_reward_display':
                return <div>
                    <ConfettiReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'balloon_reward_display':
                return <div>
                    <BalloonReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'gift_reward_display':
                return <div>
                    <GiftReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'stop_reward_display':
                return <div>
                    <StopReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'angry_reward_display':
                return <div>
                    <AngryReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'cheer_reward_display':
                return <div>
                    <CheerReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'custom_reward_display':
                return <div>
                    <CustomReward
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            default:
                return null
        }
    }
    return (
        <div>
            {rewardNavigator()}
        </div>
    );
}