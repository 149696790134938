import React, { useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

export default function EmojiAnimated(props) {
    const {
        socket,
        emotionData
    } = props;

    const [animatedEmoji, setAnimatedEmoji] = useState(null);

    useEffect(() => {
        setAnimatedEmoji(emotionData[0].id);

        if (animatedEmoji === null) {
            var i = 0;
            var interval = setInterval(function () {
                ++i
                if (i === 20) {
                    setAnimatedEmoji(null);
                    clearInterval(interval);
                    socket.removeEmojiListSocket();
                }
            }, 200);
        }
    }, [emotionData]);

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {animatedEmoji && (
                <div key={animatedEmoji} style={{
                    //paddingTop: '200px'
                }}>
                    <div style={{
                        textAlign: 'center',
                        fontSize: '30px',
                        color: 'white',
                        background: 'blue'
                    }}>
                        {emotionData[0].emoji}
                    </div>
                    <Player
                        autoplay
                        loop
                        src={`https://fonts.gstatic.com/s/e/notoemoji/latest/${animatedEmoji}/lottie.json`}
                        style={{ height: '300px', width: '300px' }}
                    />
                </div>
            )}

        </div>
    )
}

