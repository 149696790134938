import React from 'react';

import BasicCannon from './BasicCannon';
import RandomDirection from './RandomDirection';
import FireWorks from './FireWorks';
import StarsConfetti from './StarsConfetti';
import SnowConfetti from './SnowConfetti';
import SchoolPride from './SchoolPride';
import CustomShapes from './CustomShapes';

export default function ConfettiLanding(props) {
    const {
        socket,
        emotionData
    } = props;

    console.log('RewardLanding: ' + JSON.stringify(emotionData))

    function confettiNavigator() {
        switch (emotionData[0].id) {

            case 'basic_cannon_display':
                return <div>
                    <BasicCannon
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'random_direction_display':
                return <div>
                    <RandomDirection
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'fire_works_display':
                return <div>
                    <FireWorks
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'stars_confetti_display':
                return <div>
                    <StarsConfetti
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'snow_confetti_display':
                return <div>
                    <SnowConfetti
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'school_pride_display':
                return <div>
                    <SchoolPride
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            case 'custom_shapes_display':
                return <div>
                    <CustomShapes
                        socket={socket}
                        emotionData={emotionData}
                    />
                </div>

            default:
                return null
        }
    }
    return (
        <div>
            {confettiNavigator()}
        </div>
    );
}