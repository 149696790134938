import { create } from 'zustand';

const useStore = create((set) => ({
    connecting: false,
    client: null,
    displayToken: '',
    onDisplayToken: false,
    setConnectingAction: (connecting) => set({ connecting: connecting }),
    setClientAction: (client) => set(() => ({ client: client })),
    setDisplayTokenAction: (displayToken) => set(() => ({ displayToken: displayToken })),
    setOnDisplayTokenAction: (onDisplayToken) => set(() => ({ onDisplayToken: onDisplayToken })),
}))

export default useStore;
