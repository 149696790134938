import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function CustomShapes(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    var defaults = {
        scalar: 2,
        spread: 180,
        particleCount: 30,
        origin: { y: -0.1 },
        startVelocity: -35
    };
    var pumpkin = 'https://thenounproject.com/icon/pumpkin-5253388/';
    var tree = 'https://thenounproject.com/icon/pine-tree-1471679/';
    var heart = 'https://thenounproject.com/icon/heart-1545381/';

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    const fire = () => {
        if (fireRef.current) {         
            fireRef.current({
                //...defaults,
                defaults,
                shapes: [pumpkin],
                colors: ['#ff9a00', '#ff7400', '#ff4d00']
            });
            fireRef.current({
                //...defaults,
                defaults,
                shapes: [tree],
                colors: ['#8d960f', '#be0f10', '#445404']
            });
            fireRef.current({
                //...defaults,
                defaults,
                shapes: [heart],
                colors: ['#f93963', '#a10864', '#ee0b93']
            });
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}