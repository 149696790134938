import React, { useEffect } from "react";

import useVideoStore from '../store/useVideoStore';

export default function PlayerInformation() {

    const information = useVideoStore((state) => state.information);
    const setInformationAction = useVideoStore((state) => state.setInformationAction);

    useEffect(() => {
        let timer = 5;

        let interval = setInterval(function () {
            //console.log('--------> timer: ' + timer)
            if (information !== '' && timer > 0) {
                --timer;
            } else {
                setInformationAction('')
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);

    }, [information]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            fontSize: '24px',
        }}>
            <div style={{
                width: 'fit-content',
                padding: '2px 10px',
                background: 'red'
            }}>
                {information}
            </div>
        </div>
    );
}