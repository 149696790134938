import React, { useState, useEffect } from 'react';
import { useReward } from 'react-rewards';

export default function ConfettiReward(props) {
    const {
        socket,
        emotionData
    } = props;

    const [rewardStarted, setRewardStarted] = useState(false);
    const { reward: confettiReward, isAnimating: isConfettiAnimating } = useReward('confettiReward', 'confetti');

    const config = {
        elementCount: 200,
        spread: 90,
        zIndex: 30,
        lifetime: 500
    };

    useEffect(() => {
        confettiReward();
    }, [emotionData]);

    useEffect(() => {
        if (isConfettiAnimating) {
            setRewardStarted(true);
            console.log('START');
        } 
    }, [isConfettiAnimating]);

    useEffect(() => {
        if (rewardStarted === true && isConfettiAnimating === false) {
            console.log('END');
            socket.removeEmojiListSocket();
            setRewardStarted(false);
        } 
    }, [rewardStarted, isConfettiAnimating]);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 20
        }}>

            <div style={{
                marginTop: '20px',
                position: 'absolute',
                top: '70%',
                left: '50%',
                zIndex: 20
            }}>
                <span id="confettiReward" config={config} />
            </div>
        </div>
    );
}