import { create } from 'zustand';

const useEmotionStore = create((set) => ({
    featureData: null,
    setFeatureDataAction: (featureData) => set(() => ({ featureData: featureData })),
    emotionList: [],
    updateEmotionListAction: (emotionList) => set(() => ({ emotionList: emotionList })),
    /* updateEmotionListAction: (emoji) =>
        set((state) => ({
            emotionList: [
                ...state.emotionList,
                emoji,
            ],
        })), */
}))

export default useEmotionStore;
