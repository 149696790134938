import React, { useState, useEffect } from "react";
import WebFont from 'webfontloader';
import { isSmartTV, isMobile } from 'react-device-detect';

import useSocket from './useSocket';
import useStore from './useStore';
import PlayerConnect from './player/PlayerConnect';
import PlayerLanding from './player/PlayerLanding';

import './App.css';

function App() {
    const socket = useSocket();

    const client = useStore((state) => state.client);

    const [isFetching, setIsFetching] = useState(true);
    const [videoMuted, setVideoMuted] = useState(true);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Be Vietnam Pro', "Be Vietnam Pro Thin"]
            }
        });
    }, []);

    useEffect(() => {
        if (isSmartTV) {
            const timer = setTimeout(() => {
                setIsFetching(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, []);

    if (isSmartTV && isFetching) {
        return (
            <div style={{
                fontFamily: 'Be Vietnam Pro',
            }}>
                <div style={{
                    textAlign: 'center',
                    marginTop: '10%'
                }}>
                    <img src="/images/singer.gif" alt="crypto dashboard" />
                </div>
                <div style={{
                    textAlign: 'center',
                    color: '#e91e63',
                    fontSize: '30pt',
                    fontWeight: 700,
                    marginBottom: '20px'
                }}>
                    Hát Vui Vẻ
                </div>
            </div>
        );
    }

    return (
        <div style={{
            fontFamily: 'Be Vietnam Pro',
        }}>
            {
                isMobile ?
                    window.location.replace("https://hatvuive.com.vn")
                    :
                    client ?
                        <PlayerLanding socket={socket} videoMuted={videoMuted} />
                        :
                        <PlayerConnect socket={socket} setVideoMuted={setVideoMuted} />
            }
        </div>
    );
}

export default App;