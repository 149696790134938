import React, { useRef } from "react";
import VideoJS from './VideoJS';

//import useStore from './useStore';
import useVideoStore from './store/useVideoStore';

function VideoController(props) {
    const {
        socket,
        videoMuted,
    } = props;

    const playerRef = useRef(null);
    const playList = useVideoStore((state) => state.playList);

    const videoJsOptions = {
        autoplay: true,
        loadingSpinner: true,
        muted: videoMuted,
        controls: false,
        responsive: true,
        fluid: true,
        webkitPlaysinline: true,
        playsinline: true,
        webkitAutoplay: true,
        webkitMuted: true,
        //origin: '*',
        origin: 'https://hatvuive.com',
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        socket.setPlayerRefSocket(player);

        player.on('ready', () => {
            //console.log('player is ready');
        });

        player.on('dispose', () => {
            //console.log('player will dispose');
        });

        player.on('waiting', () => {
            //console.log('player is waiting');
        });

        player.on('loadedmetadata', () => {
            //console.log('player with loadedmetadata');
        });

        player.on('play', () => {
            //console.log('player play');
            socket.playerPlayVideoSocket();
        });

        player.on('pause', function () {
            //console.log('player pause');
            socket.playerPauseVideoSocket();
        });

        player.on('playing', () => {
            //console.log('player is playing');
        });

        player.on('ended', () => {
            //console.log('player play next');
            socket.playerPlayNextSocket();
        });


        player.on('error', () => {
            console.log('Cannot play this song');
            //client.removePlaylistSong(0);
            playerRef.current.playlist.next();
        })
    };

    return (
        <>
            <VideoJS
                options={videoJsOptions}
                onReady={handlePlayerReady}
                playList={playList}
            />
        </>

    );
}

export default VideoController;
