import React from "react";
import { QRCodeSVG } from 'qrcode.react';

import useStore from '../useStore';
import useVideoStore from '../store/useVideoStore';
import CountDownNow from './CountDownNow';
import SongTitleRunning from './SongTitleRunning';
import HourTimer from './HourTimer';
import MinuteTimer from './MinuteTimer';
import PlayerInformation from './PlayerInformation';

const PlayerInfoDisplay = () => {

    const client = useStore((state) => state.client);
    const displayToken = useStore((state) => state.displayToken);
    const onDisplayToken = useStore((state) => state.onDisplayToken);
    const setDisplayTokenAction = useStore((state) => state.setDisplayTokenAction);

    const information = useVideoStore((state) => state.information);
    const playList = useVideoStore((state) => state.playList);

    //console.log('playList: ' + JSON.stringify(playList[0]));

    function imageBackgroud() {
        switch (playList[0].label) {
            case 'soundcloud':
                return 'url("../images/soundcloud.jpg")'

            case 'mixcloud':
                return 'url("../images/mixcloud.jpg")'

            default:
                return null
        }
    }

    return (
        <div style={{
            backgroundImage: playList.length > 0 && imageBackgroud(),
            backgroundSize: playList.length > 0 && playList[0].label === 'youtube' ? null : '100%',
            backgroundRepeat: playList.length > 0 && playList[0].label === 'youtube' ? null : 'no-repeat',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 20,
            color: 'white'
        }}>
            <div style={{
                position: 'absolute',
                top: 10,
                left: 10,
                //background: 'green'
            }}>
                {
                    playList.length > 0 && playList[0].label === 'youtube' ? <MinuteTimer /> : <HourTimer />
                }
            </div>
            <div style={{
                position: 'absolute',
                top: 10,
                left: '20%',
                right: '15%',
                textAlign: 'left',
                //background: 'pink'
            }}>
                {information !== '' ? <PlayerInformation /> : <SongTitleRunning />}
            </div>
            <div style={{
                position: 'absolute',
                top: 20,
                right: 20,
                //background: 'yellow'
            }}>
                <QRCodeSVG
                    value={`${process.env.REACT_APP_QR_ENDPOINT}?product_id=karaoke-web&room_id=${client.room_id}`}
                    includeMargin={true}
                    bgColor={'#eeeeee'}
                />
                <div style={{ textAlign: 'center' }}>
                    {
                        displayToken !== '' && <div>
                            {displayToken}
                            <CountDownNow
                                setDisplayTokenAction={setDisplayTokenAction}
                                onDisplayToken={onDisplayToken}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PlayerInfoDisplay;