import React, { useEffect } from "react";

import useVideoStore from '../store/useVideoStore';
import useHourTimer from './useHourTimer';

export default function HourTimer() {
    const {
        renderedStreamDuration,
        startHandler,
        stopHandler,
        pauseHandler,
        resumeHandler,
    } = useHourTimer();

    const playList = useVideoStore((state) => state.playList);
    const playState = useVideoStore((state) => state.playState);

    useEffect(() => {
        //console.log('---> playState: ' + playState);
        if (playState === 'play') {
            startHandler();
        } else if (playState === 'pause') {
            pauseHandler();
        } else if (playState === 'stop') {
            stopHandler();
        }

    }, [playState]);

    if (playList.length === 0) return;

    //console.log('---> HourTimer - timeDuration: ' + playList[0].duration);

    const formatHourTimer = (value) => {
        const hours = Math.floor(value / 3600000);
        const minutes = Math.floor((value % 3600000) / 60000);
        const seconds = Math.floor((value % 60000) / 1000);

        return `${("0" + hours.toString()).slice(-2)}:${("0" + minutes.toString()).slice(-2)}:${("0" + seconds.toString()).slice(-2)}`;
    };

    return (
        <div style={{
            display: 'flex',
            fontSize: '24px',
            color: '#FFFF00',
        }}>
            <div style={{ minWidth: '114px', textAlign: 'left' }}>
                {renderedStreamDuration}
            </div>
            <div style={{ marginRight: '8px' }}>
                /
            </div>
            <div>
                {formatHourTimer(playList[0].duration)}
            </div>
        </div>
    );
}