import { create } from 'zustand';

const useVideoStore = create((set) => ({
    playState: 'stop',
    setPlayStateAction: (playState) => set({ playState: playState }),
    playList: [],
    setPlayListAction: (playList) => set(() => ({ playList: playList })),
    information: '',
    setInformationAction: (information) => set(() => ({ information: information })),
    videoTimer: '',
    setVideoTimerAction: (videoTimer) => set(() => ({ videoTimer: videoTimer })),
}))

export default useVideoStore;
