import React, { useRef } from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-playlist';
import "videojs-youtube";

function VideoJS(props) {
    const {
        options,
        onReady,
        playList
    } = props;

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    //console.log('playList: ' + JSON.stringify(playList));
    //console.log(">> playList.length: " + playList.length);

    React.useEffect(() => {

        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });

            player.volume(0.5);
            player.loop(true);
            playerRef.current.src({ src: `${process.env.REACT_APP_API_ENDPOINT}/api/intro`, type: "video/mp4" });

        } else {
            if (playList.length === 0) {
                playerRef.current.loop(true);
                playerRef.current.src({ src: `${process.env.REACT_APP_API_ENDPOINT}/api/intro`, type: "video/mp4" });
            }
            else {
                playerRef.current.loop(false);
                playerRef.current.playlist(playList);
            }
        }
    }, [options, videoRef, onReady, playList]);

    React.useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    

    return (
        <div>
            <video
                ref={videoRef}
                className="video-js vjs-default-skin"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: '100%',
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                    zIndex: 10,
                }}
            />
        </div>
    );
}

export default VideoJS;
