import React, { useEffect, useRef, useCallback } from 'react';

export default function SingleBottomUp(props) {
  const {
    socket,
    emojiMessage,
  } = props;

  const overlayRef = useRef();

  const handleRemoveFlyingEmoji = useCallback((node) => {
    if (!overlayRef.current) return;

    console.log("animation end");
    socket.removeEmojiListSocket();
    overlayRef.current.removeChild(node);
  }, []);

  const handleDisplayFlyingEmoji = (emoji) => {
    if (!overlayRef.current) {
      return;
    }

    const node = document.createElement('div');
    //node.appendChild(document.createTextNode(emoji));
    node.innerHTML = emoji;
    node.className = 'emoji wiggle-1'
    node.style.transform = `rotate(${-30 + Math.random() * 60}deg)`;
    overlayRef.current.appendChild(node);

    node.addEventListener('animationend', (e) => {
      handleRemoveFlyingEmoji(e.target);
    });
  }

  useEffect(() => {
    console.log("animation start");
    handleDisplayFlyingEmoji(emojiMessage)
  }, [handleDisplayFlyingEmoji]);

  return (
    <>
      {handleDisplayFlyingEmoji(emojiMessage)}

      <div className="flying-emojis" ref={overlayRef}>
        <style>
          {`
        .flying-emojis {
          position: fixed;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          overflow: hidden;
          pointer-events: none;
          user-select: none;
          z-index: 99;
        }

        .flying-emojis .emoji {
          position: absolute;
          left: 50%;
          bottom: 60px;
          font-size: 48px;
          line-height: 1;
          width: 48px;
          height: 48px;
        }

        .flying-emojis .emoji.wiggle-1 {
          animation: emerge 3s forwards, wiggle-1 1s ease-in-out infinite alternate;
        }
                    
        @keyframes emerge {
          to {
            bottom: 85%;
            opacity: 0;
          }
        }

        @keyframes wiggle-1 {
          from {
            margin-left: -20px;
          }
          to {
            margin-left: 50px;
          }
        }
      `}
        </style>
      </div>
    </>

  );
};