import React from 'react';

export default function TipLanding() {

    return (
        <div style={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            zIndex: 20
        }}>
            Tip Feature
        </div>
    )
}