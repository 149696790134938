import Snowflakes from 'magic-snowflakes';

export default function HeavySnow(props) {
    const {
        socket,
    } = props;

    const snowflakes = new Snowflakes({
        minSize: 10,
        maxSize: 100,
        color: 'white',
        wind: true
    });
    snowflakes.start();

    var i = 0;
    var interval = setInterval(function () {
        ++i;
        if (i === 20) {
            snowflakes.destroy();
            clearInterval(interval);
            socket.removeEmojiListSocket();
        }
    }, 200);
}
