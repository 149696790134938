import React from 'react';

import useEmotionStore from '../store/useEmotionStore';

import EmojiLanding from './emotion/emoji/EmojiLanding';
import FallingSnowLanding from './emotion/fallingsnow/FallingSnowLanding';
import ConfettiLanding from './emotion/confetti/ConfettiLanding';
import RewardLanding from './emotion/reactrewards/RewardLanding';
import EmojiAnimated from './emotion/emojianimated/EmojiAnimated';

import TipLanding from './tip/TipLanding';

export default function FeatureLanding(props) {
    const {
        socket
    } = props;

    const emotionList = useEmotionStore((state) => state.emotionList);

    if (emotionList.length === 0) return;

    //console.log('3. FeatureLanding - emotionList: ' + JSON.stringify(emotionList.length));
    //console.log('4. FeatureLanding - emotionList: ' + JSON.stringify(emotionList[0].name));

    function featureRouting(emotionObj) {
        //console.log('featureRouting - emotionObj: '+JSON.stringify(emotionObj));

        switch (emotionObj.name) {
            case 'emoji_basic':
                return <div>
                    <EmojiLanding
                        socket={socket}
                        emotionData={emotionObj.message}
                    />
                </div>

            case 'falling_snow':
                return <div>
                    <FallingSnowLanding
                        socket={socket}
                        emotionData={emotionObj.message}
                    />
                </div>

            case 'canvas_confetti':
                return <ConfettiLanding
                    socket={socket}
                    emotionData={emotionObj.message}
                />

            case 'react_reward':
                return <RewardLanding
                    socket={socket}
                    emotionData={emotionObj.message}
                />

            case 'emoji_animated':
                return <EmojiAnimated
                    socket={socket}
                    emotionData={emotionObj.message}
                />

            case 'tip_feature':
                return <div>
                    <TipLanding />
                </div>

            case 'chat_feature':
                return <div>
                    Chat Feature
                </div>

            case 'remote_feature':
                return <div>
                    Remote Feature
                </div>

            default:
                return null
        }
    }

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 30
        }}>
            {
                featureRouting(emotionList[0])
            }
        </div>
    )
}

