import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function SnowConfetti(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var skew = 1;

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });
            fire();
        }
    }, [emotionData]);

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    const fire = () => {
        if (fireRef.current) {
            var timeLeft = animationEnd - Date.now();
            var ticks = Math.max(200, 500 * (timeLeft / duration));
            skew = Math.max(0.8, skew - 0.001);

            fireRef.current({
                particleCount: 1,
                startVelocity: 0,
                ticks: ticks,
                origin: {
                    x: Math.random(),
                    // since particles fall down, skew start toward the top
                    y: (Math.random() * skew) - 0.2
                },
                colors: ['#ffffff'],
                shapes: ['circle'],
                gravity: randomInRange(0.4, 0.6),
                scalar: randomInRange(0.4, 1),
                drift: randomInRange(-0.4, 0.4)
            });

            if (timeLeft > 0) {
                requestAnimationFrame(fire);
            } else {
                console.log('i am here at clearInterval');
                fireRef.current.reset();
                socket.removeEmojiListSocket();
            }
        }
    };

    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}