import React, { useRef, useEffect } from 'react';
import { create } from "canvas-confetti";

export default function StarsConfetti(props) {
    const {
        socket,
        emotionData
    } = props;

    const canvasRef = useRef(null), fireRef = useRef(null);

    var defaults = {
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
    };

    useEffect(() => {
        if (canvasRef.current) {
            console.log('i am here at 1')
            fireRef.current = create(canvasRef.current, {
                resize: true,
            });

            setTimeout(fire, 0);
            setTimeout(fire, 100);
            setTimeout(fire, 200);
        }
    }, [emotionData]);

    const fire = () => {
        let cloneDefaults = Object.assign({}, defaults);

        if (fireRef.current) {
            fireRef.current({
                //...defaults,
                cloneDefaults,
                particleCount: 40,
                scalar: 1.2,
                shapes: ['star']
            });

            fireRef.current({
                //...defaults,
                cloneDefaults,
                particleCount: 10,
                scalar: 0.75,
                shapes: ['circle']
            });

            var i = 0;
            var interval = setInterval(() => {
                if (i < 3) {
                    ++i
                    console.log('i: ' + i);
                } else {
                    console.log('i am here at clearInterval');
                    fireRef.current.reset();
                    socket.removeEmojiListSocket();
                    clearInterval(interval);
                }

            }, 1000);
        }
    };



    return (
        <div>
            <div >
                <canvas ref={canvasRef} style={{ width: '100vw', height: '70vh' }} />
            </div>
        </div>
    );
}