import { useEffect, useRef } from "react";
import io from 'socket.io-client';
import useStore from './useStore';
import useVideoStore from './store/useVideoStore';
import useEmotionStore from './store/useEmotionStore';

const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT, { autoConnect: false });

const useSocket = () => {
    const playerRef = useRef(null);

    const setConnectingAction = useStore((state) => state.setConnectingAction);
    const setClientAction = useStore((state) => state.setClientAction);
    const setDisplayTokenAction = useStore((state) => state.setDisplayTokenAction);
    const setOnDisplayTokenAction = useStore((state) => state.setOnDisplayTokenAction);
    
    const setPlayListAction = useVideoStore((state) => state.setPlayListAction);
    const setPlayStateAction = useVideoStore((state) => state.setPlayStateAction);
    const setInformationAction = useVideoStore((state) => state.setInformationAction);

    const setFeatureDataAction = useEmotionStore((state) => state.setFeatureDataAction);
    const updateEmotionListAction = useEmotionStore((state) => state.updateEmotionListAction);

    useEffect(() => {
        let session = JSON.parse(sessionStorage.getItem("web_client"));

        if (session) {
            if (session.client_role === 'player') {
                sessionStorage.removeItem("web_client");
                socket.disconnect();

            } else {
                socket.auth = { session };
                socket.connect();
                console.log("Trying to reconnect with session");
            }
        }

        socket.on("connect", () => {
            //console.log("Connected to server successfully");
        });

        socket.on("karaoke_room_id", (client) => {
            //console.log('karaoke_room_id: ' + JSON.stringify(client));
            setClientAction(client);
        });

        socket.on("display_token", (token) => {
            //console.log('useSocket - display_token: '+token)
            setOnDisplayTokenAction(true);
            setDisplayTokenAction(token);
        });

        socket.on("close_verify_2fa", () => {
            setOnDisplayTokenAction(false);
        });

        socket.on("disconnect", (reason) => {
            console.log('--------------disconnect-----------------reason: ' + reason);
            //server down will force only player close. Controller is not affected
            //If will let controller clse in here, we will make controller close take and retake qr everytime it is in sleep mode

            if (reason === 'transport close') {
                //server suddently shutdown               
                setClientAction(null);
                socket.disconnect();
                setConnectingAction(false);
            } else if (reason === 'transport error') {

            } else if (reason === 'io client disconnect') {

            }

        });

        socket.on("connect_error", (error) => {
            console.log('--------------connect_error-----------------');
            //when server down, all controllers will try to reconnect but error
            //after error catched we will close controller as below

            setClientAction(null);
            socket.disconnect();
            setConnectingAction(false);
        });





        socket.on("add_playlist_song", (song, media) => {
            setPlayListAction(song);
        });

        socket.on("player_play_video", () => {
            setPlayStateAction('play');
            //setInformationAction('tiếp tục phát');
        });

        socket.on("player_pause_video", () => {
            setPlayStateAction('pause');
            //setInformationAction('tạm dừng');
        });

        socket.on("controller_play_video", () => {
            playerRef.current.play();
            setPlayStateAction('play');
            setInformationAction('tiếp tục phát');
        });

        socket.on("controller_pause_video", () => {
            playerRef.current.pause();
            setPlayStateAction('pause');
            setInformationAction('tạm dừng');
        });

        socket.on("controller_repeat_song", (media) => {
            if (media.repeatSong) {
                setInformationAction('hát hai lần');
            } else {
                setInformationAction('hủy hát hai lần');
            }
        });

        socket.on("controller_skip_next", (song, media) => {
            setPlayListAction(song);
            playerRef.current.playlist.next();
            setPlayStateAction('stop');

            if (song.length > 0) {
                setInformationAction('chuyển bài kế tiếp');
            } 
        });

        socket.on("player_play_next", (sang, song, media) => {
            if (media.repeatSong) {
                playerRef.current.play();
                socket.emit("controller_repeat_song");
            } else {
                playerRef.current.pause();
                setPlayListAction(song);
                playerRef.current.playlist.next();
                setPlayStateAction('stop');
            }
        });

        socket.on("controller_current_volume", (volume) => {
            playerRef.current.volume(volume);
            let volume_level = volume*100;
            setInformationAction(`âm lượng: ${volume_level}%`);
        });

        socket.on("controller_queuing_up", (song) => {
            setPlayListAction(song);
        });

        socket.on("controller_delete_queue", (song) => {
            setPlayListAction(song);
        });




        socket.on("display_emoji_message", (data) => {
            //console.log('SOCKET - display_emoji_message - data: '+JSON.stringify(data));
            setFeatureDataAction(data);
        });

        socket.on("update_emoji_list", (list) => {
            updateEmotionListAction(list);
        });



        return () => {
            socket.disconnect();
        }

    }, [setConnectingAction, setClientAction, setDisplayTokenAction, setOnDisplayTokenAction, setPlayListAction, setPlayStateAction, setInformationAction, setFeatureDataAction, updateEmotionListAction]);

    const playerLogIn = ({ clientName, clientRole, clientBrowser }) => {
        socket.auth = { clientName, clientRole, clientBrowser };
        socket.connect();
    }

    const setPlayerRefSocket = (player) => {
        playerRef.current = player;
    }

    const playerPlayVideoSocket = () => {
        //console.log('playerPlayVideoSocket');
        socket.emit("player_play_video");
    }

    const playerPauseVideoSocket = () => {
        //console.log('playerPauseVideoSocket');
        socket.emit("player_pause_video");
    }

    const playerPlayNextSocket = () => {
        //console.log('playerPlayNextSocket');
        socket.emit("player_play_next");
    }

    const removeEmojiListSocket = () => {
        //console.log('playerPlayNextSocket');
        socket.emit("remove_emoji_list");
    }

    return {
        socket,
        playerLogIn,
        setPlayerRefSocket,
        playerPlayVideoSocket,
        playerPauseVideoSocket,
        playerPlayNextSocket,
        removeEmojiListSocket
    };
}

export default useSocket;